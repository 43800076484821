<template>
  <!--
    The view for the ActionCam Configuration
  -->
  <div class="installationDetail_ActionCam">
    <template>
      <div class="card">
        <Portlet
          title="Actioncam Configuration"
          icon="camera"
          class="actioncamConfig"
        >
          <div
            class="wrapper"
          >
            <div class="wrapp-cont">
              <ActioncamTriggerConfiguration
                :installation-id="installationId"
                :device-id="deviceId"
                :pt-configuration="configuration"
                style="max-width: 750px; width: 100%;"
              />
              <ActioncamDetailConfiguration 
                class="d-none d-md-block"
                :device-id="deviceId"
              />
            </div>
            <div
              class="wrapp-cont mt-3"
            >
              <br>
              <br>
              <div
                style="max-width: 750px; width: 100%;"
              >
                <div
                  class="row"
                  style="justify-content: space-between;"
                >
                  <div
                    v-if="!cropLoading"
                    class="ml-4"
                  >
                    <input
                      id="rotate90"
                      v-model="rotate"
                      type="checkbox"
                      class="form-check-input"
                    >
                    <label
                      class="form-check-label"
                      for="rotate90"
                    >Rotate 90</label>
                  </div>
                  <font-awesome-icon
                    :class="['alt-pointer color-primary mr-2', { 'fa-spin' : cropLoading}]"
                    icon="sync-alt"
                    style="vertical-align: middle; float: right;"
                    @click="refreshCamPhoto()"
                  />
                </div>
                <ActioncamCropRectConfiguration
                  ref="camCrop"
                  class="mt-4"
                  :installation-id="installationId"
                  :device-id="deviceId"
                  :rotate="rotate"
                  style="max-width: 750px; width: 100%;"
                  @img-base="setExif"
                  @cropLoading="setCropLoading"
                  @reloadConfiguration="getPtConfig"
                />
                <ActioncamDetailExifAndConfiguration 
                  class="mt-5"
                  style="width: 45%"
                  :device-id="deviceId"
                  :installation-id="installationId"
                  :is-exif="false"
                  :title="'Configuration'"
                  :exif-data="null"
                  :conf-data="configuration"
                />
              </div>
              
              <ActioncamDetailExifAndConfiguration 
                class="ml-4 d-none d-md-block"
                :device-id="deviceId"
                :installation-id="installationId"
                :is-exif="true"
                :title="'Exif'"
                :exif-data="exifData"
              />
            </div>
          </div>
        </Portlet>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "InstallationDetailActioncam",
  components: {
    ActioncamTriggerConfiguration: () => import('@/components/Actioncam/ActioncamTriggerConfiguration.vue'),
    ActioncamCropRectConfiguration: () => import('@/components/Actioncam/ActioncamCropRectConfiguration.vue'),
    ActioncamDetailConfiguration: () => import('@/components/Actioncam/ActioncamDetailConfiguration.vue'),
    ActioncamDetailExifAndConfiguration: () => import('@/components/Actioncam/ActioncamDetailExifAndConfiguration.vue')
  },
  props: {
    installationId: {
      type: String,
      required: true
    },
    deviceId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      exifData: [],
      configuration: [],
      device: null,
      cropLoading: true,
      rotate: false
    }
  },
  created () {
    this.getPtConfig();
  },
  metaInfo () {
    return {
      title: 'Actioncam'
    }
  },
  methods: {
    pathTo (endpoint) { 
      //return '/installation/SKISUP-9596/devices';
      return '/installation/' + this.installationId + '/actioncam/' + this.deviceId + endpoint;
    },
    setExif (arr) {
      this.exifData = arr;
    },
    getPtConfig () {
      this.axios.get(`/Actioncam/GetActioncamStringConfiguration/${this.installationId}/${this.deviceId}`)
        .then((response) => {
          if (!response) {
            return;
          }
          if (!response.data) {
            return;
          }
          this.configuration = response.data;
        })
    },
    refreshCamPhoto () {
      this.$refs.camCrop.refresh();
    },
    setCropLoading (val) {
      this.cropLoading = val;
    }
  }
}
</script>

<style scoped>

.wrapp-cont {
  position: relative;
  width: 100%; 
  display: flex; 
  flex-direction: row; 
  flex: 0 0 100%;
}
</style>